import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Plus from 'img/plus.inline.svg'

// Interface
import { PartnersProps } from 'components/flex/Partners/PartnersShell'
import NavLink from 'components/shared/NavLink'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts, useBlogBink } from '@ubo/blog-bink'

const PartnerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
`

const Content = styled(ParseContent)`
  @media (min-width: 576px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 2rem;
    }

    & p {
      line-height: ${({ theme }) => theme.font.size.larger};
    }
  }
`

const Image = styled(Plaatjie)`
  opacity: 0.8;
  height: 90px;

  & img {
    object-fit: contain !important;
  }

  &:hover {
    opacity: 1;
  }
`

const SvgWrapper = styled.div``

const Highlighted: React.FC<PartnersProps> = ({ fields }) => {
  const {
    allWpPartner,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedPartnersQueryQuery>(graphql`
    query highlightedPartnersQuery {
      allWpPartner(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPartnerFragment
          }
        }
      }
    }
  `)

  const partners: unknown = allWpPartner.edges
  const postIds = fields.partners?.map((partner) => partner?.databaseId)

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        showIds={postIds as Array<number>}
        posts={partners as BlogBinkPosts}
        id="highlighted-partners"
        limit={Infinity}
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row align-items-start">
                <div className="col-md-6 d-flex justify-content-center align-items-center mb-5 mb-md-0">
                  <SvgWrapper className="position-absolute">
                    <Plus />
                  </SvgWrapper>
                  <BlogGrid fields={fields} />
                </div>
                <div className="col-md-6">
                  <Content content={fields.description} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlogBink>
    </section>
  )
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        <PartnerGrid>
          {blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <div>
                <Image image={node?.recap.thumbnail} alt="" />
                <span className="text-hidden">{node.title}</span>
              </div>
            )
          })}
        </PartnerGrid>
      </div>
    </div>
  )
}

export default Highlighted
